// Moneda
export const useCurrencyStore = defineStore('currencyStore', () => {
  const currency = useCookie(
    'currency',
    {
      default: () => ('EUR'),
      watch: true
    }
  )

  return { currency }
})
