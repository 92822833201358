// Adaptar url en base a boolean de strapi
export const getButtonUrl = (button: any) => {
  const runtimeConfig = useRuntimeConfig()
  const { locale } = useI18n()
  if(button?.url) {
    return button?.link
  }else{
    if(button?.key){
      return `${runtimeConfig.public.BASE_URL}/${ locale.value }/${ button?.link }-${ button.key }d.html`
    }else{
      return `${runtimeConfig.public.BASE_URL}/${ button?.link }.${ locale.value }.html`
    }
  }
}

// Función para verificar si un objeto está vacío
export const objIsEmpty = (obj: Object) => {
  if(obj === null || obj === undefined){
    return true
  }
  return Object.keys(obj).length === 0;
}